<div
  id="carousel-container"
  (pan)="onSwipe($event)"
  *ngIf="tiles && tile && visible"
>
  <ngb-carousel #carousel [activeId]="tile.id" [interval]="0">
    <ng-template ngbSlide *ngFor="let t of tiles" [id]="t.id">
      <div class="img-wrapper">
        <img
          src="{{ cms + t.attributes.cover.data.attributes.url }}"
          alt="Random first slide"
        />
      </div>

      <div class="carousel-caption">
        <h3>{{ t.attributes.publisher }}</h3>
      </div>
    </ng-template>
  </ngb-carousel>
  <a href="" id="hide">
    <fa-icon [icon]="faTimes" (click)="visible = false"></fa-icon>
  </a>
</div>
