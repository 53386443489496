<mat-toolbar>
  <mat-toolbar-row>
    <div id="container">
      <a href="#"
         (click)="hideCarousel()">
        <img src="assets/Header.svg"
             id="header">
      </a>
      <span id="link">
        <a id="contact"
           href="mailto:remi@les-collegues.net">
          CONTACT /
        </a>
        <a href="https://www.instagram.com/remipepino/"
           target="_blank"
           id="link">
          <fa-icon [icon]="faInstagram"></fa-icon>
        </a>
      </span>
    </div>

  </mat-toolbar-row>
</mat-toolbar>